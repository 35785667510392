"use client";
import BlokWrap from "@/components/ui/BlokWrap";
import { HubspotFormStoryblok } from "@/lib/types/storyblok-blok-types";
import Script from "next/script";
import { useCallback, useEffect, useId, useState } from "react";
import styles from "./index.module.css";

type Props = {
  blok: HubspotFormStoryblok;
};

const HubspotForm = ({ blok }: Props) => {
  const [loaded, setLoaded] = useState(false);
  const id = useId();
  const formattedId = id.replaceAll(":", "_");
  const formId = blok.hubspotId;

  const createForm = useCallback(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "7233060",
        formId: formId!,
        target: `#${formattedId}`,
      });
    }
  }, [formId, formattedId]);

  useEffect(() => {
    if (!loaded) return;
    createForm();
  }, [createForm, loaded]);
  return (
    <BlokWrap data-layout="page" editable={blok} animated="fadeY">
      <div id={formattedId} className={styles.hubspotForm} />
      <Script
        onLoad={() => {
          setLoaded(true);
        }}
        strategy="afterInteractive"
        src="https://js.hsforms.net/forms/v2.js"
      />
    </BlokWrap>
  );
};

export default HubspotForm;

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (config: {
          portalId: string;
          formId: string;
          target: string;
        }) => void;
      };
    };
  }
}
