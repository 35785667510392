"use client";

import Button from "@/components/ui/Button";
import { RenewCookieButtonStoryblok } from "@/lib/types/storyblok-blok-types";
import type { BlokProps } from "@frend-digital/storyblok";

export const RenewCookieButtonBlok = ({
  blok,
}: BlokProps<RenewCookieButtonStoryblok>) => {
  function renew() {
    try {
      var _hsp = (window._hsp = window._hsp || []);
      _hsp.push(["showBanner"]);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Button id="hs_show_banner_button" mode="link" onClick={renew}>
      {blok.text}
    </Button>
  );
};

declare global {
  interface Window {
    _hsp: any;
    _hsq: any;
  }
}
